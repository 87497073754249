import { useEffect } from 'react';
import { type SubmitHandler, useForm } from 'react-hook-form';

import { Button, FormBase } from '@gbs-monorepo-packages/common';
import { zodResolver } from '@hookform/resolvers/zod';

import { type EmailLinkSchema, emailLinkSchema } from '../../modalLinkSchema';
import { ContentButton, FormContent } from './styles';

interface IFormWebLinkProps {
  onSubmit: SubmitHandler<EmailLinkSchema>;
  onCancel: () => void;
  componentValue?: { body?: string; email?: string; subject?: string };
}

export const FormMailTo = ({
  onSubmit,
  onCancel,
  componentValue,
}: IFormWebLinkProps): JSX.Element => {
  const formSchema = useForm<EmailLinkSchema>({
    resolver: zodResolver(emailLinkSchema),
    defaultValues: {
      email: '',
      subject: '',
      body: '',
    },
  });

  const {
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
  } = formSchema;
  const values = watch();

  const handleCancel = () => {
    onCancel();
  };

  useEffect(() => {
    setValue('body', componentValue?.body ?? '');
    setValue('email', componentValue?.email ?? '');
    setValue('subject', componentValue?.subject ?? '');
  }, [componentValue, setValue]);

  return (
    <FormBase.Provider {...formSchema}>
      <FormBase.Root data-cy="formMailTo" onSubmit={handleSubmit(onSubmit)}>
        <FormContent>
          <FormBase.InputContent
            filled={!!values?.email}
            inputRef="email"
            label="Email"
            errorMessage={errors.email?.message}
            dataCy="content-email"
          >
            <FormBase.InputText
              autoComplete="off"
              dataCy="input-email"
              id="email"
              type="text"
              name="email"
            />
          </FormBase.InputContent>
          <FormBase.InputContent
            filled={!!values?.subject}
            inputRef="subject"
            label="Subject"
            errorMessage={errors.subject?.message}
            dataCy="content-subject"
          >
            <FormBase.InputText
              autoComplete="off"
              dataCy="input-subject"
              id="subject"
              type="text"
              name="subject"
            />
          </FormBase.InputContent>
          <FormBase.InputContent
            filled={!!values?.body}
            inputRef="body"
            label="Body"
            errorMessage={errors.body?.message}
            dataCy="content-body"
          >
            <FormBase.InputText
              autoComplete="off"
              dataCy="input-body"
              id="body"
              type="text"
              name="body"
            />
          </FormBase.InputContent>
          <ContentButton>
            <Button type="button" styleType="outline" onClick={handleCancel}>
              Cancel
            </Button>
            <Button type="submit">Create Link</Button>
          </ContentButton>
        </FormContent>
      </FormBase.Root>
    </FormBase.Provider>
  );
};
