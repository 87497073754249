import { useEffect } from 'react';
import { type SubmitHandler, useForm } from 'react-hook-form';

import { Button, FormBase } from '@gbs-monorepo-packages/common';
import { zodResolver } from '@hookform/resolvers/zod';

import { type WebLinkSchema, webLinkSchema } from '../../modalLinkSchema';
import { RadioButton } from '../RadioButton';
import { ContentButton, FormContent } from './styles';

interface IFormWebLinkProps {
  onSubmit: SubmitHandler<WebLinkSchema>;
  onCancel: () => void;
  componentValue?: { title: string; url: string };
}

export const FormWebLink = ({
  onSubmit,
  onCancel,
  componentValue,
}: IFormWebLinkProps): JSX.Element => {
  const formSchema = useForm<WebLinkSchema>({
    resolver: zodResolver(webLinkSchema),
    defaultValues: {
      title: '',
      url: '',
      target: '_blank',
    },
  });

  const {
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
  } = formSchema;
  const values = watch();

  const handleCancel = () => {
    onCancel();
  };

  useEffect(() => {
    setValue('title', componentValue?.title ?? '');
    setValue('url', componentValue?.url ?? '');
  }, [componentValue, setValue]);

  return (
    <FormBase.Provider {...formSchema}>
      <FormBase.Root id="formWebLink" onSubmit={handleSubmit(onSubmit)}>
        <FormContent>
          <FormBase.InputContent
            filled={!!values?.title}
            inputRef="title"
            label="Title"
            errorMessage={errors.title?.message}
            dataCy="content-title"
          >
            <FormBase.InputText
              autoComplete="off"
              dataCy="input-title"
              id="title"
              type="text"
              name="title"
            />
          </FormBase.InputContent>
          <FormBase.InputContent
            filled={!!values?.url}
            inputRef="url"
            label="URL"
            errorMessage={errors.url?.message}
            dataCy="content-url"
          >
            <FormBase.InputText
              autoComplete="off"
              dataCy="input-url"
              id="url"
              type="text"
              name="url"
            />
          </FormBase.InputContent>
          <RadioButton
            dataCy="content-target"
            id="target"
            onValue={(value) => {
              formSchema.setValue('target', value as '_blank' | '_self');
            }}
          />
          <ContentButton>
            <Button type="button" styleType="outline" onClick={handleCancel}>
              Cancel
            </Button>
            <Button type="submit">Create Link</Button>
          </ContentButton>
        </FormContent>
      </FormBase.Root>
    </FormBase.Provider>
  );
};
